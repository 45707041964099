// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/ui/Container"
const isBackgroundRed = true
const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Page two" />

    <div className="mt2rm p-[15px] bg1">
      <br />
      <div className="grid lg:grid-cols-2  md:grid-cols-1 gap-4 place-items-center ">
        <div className="items-end ml20 ">
          {" "}
          <span className="contitle">Work With Us</span>
          <br />
          <h1 className="context1 mt-4">Take your Career to the next level</h1>
          <br />
          <p className="contpara">
            Make an impact by working with our #SuperStarAI team. We’re home to
            a diverse team of problem solvers on a mission to democratize AI.
          </p>
        </div>
        <div className="">
          <img
            className="w-full h-[25rem]"
            src="/images/team.png"
            alt="Sunset in the mountains"
          />
        </div>
      </div>
    </div>

    {/* <div className="xl:px-20 px-8 py-20 2xl:mx-auto 2xl:container relative z-40">
      <CarouselProvider
        naturalSlideWidth={100}
        isIntrinsicHeight={true}
        totalSlides={2}
      >
        <h1 className="text-5xl font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
          What our customers are saying
        </h1> */}
    {/* <Slider>
          <Slide index={0} tabIndex="null">
            <div className="flex">
              <div className="mt-14 md:flex">
                <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                  <img
                    src="https://i.ibb.co/4g1D9cv/imgslider1.png"
                    alt="image of profile"
                    className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded"
                  />
                  <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32  rounded-full"></div>
                </div>
                <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                  <div>
                    <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">
                      Some of the best work that was done!
                    </h1>
                    <p className="text-base font-medium leading-6 mt-4 text-gray-600">
                      Our core values are at the heart of all that we do. They
                      are integrated into our daily work lives and help us to
                      remember our customers always comes first, the last thank
                      you should always comes from us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <Slide index={1}>
            <div
              className="flex relative"
              style={{ transform: "translateX(0%)" }}
            >
              <div className="mt-14 md:flex">
                <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                  <img
                    src="https://i.ibb.co/4g1D9cv/imgslider1.png"
                    alt="image of profile"
                    className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded"
                  />
                  <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-indigo-100 rounded-full">
                    <img
                      src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg"
                      alt="commas"
                    />
                  </div>
                </div>
                <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                  <div>
                    <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">
                      Some of the best work that was done!
                    </h1>
                    <p className="text-base font-medium leading-6 mt-4 text-gray-600">
                      Our core values are at the heart of all that we do. They
                      are integrated into our daily work lives and help us to
                      remember our customers always comes first, the last thank
                      you should always comes from us.
                    </p>
                  </div>
                  <div className="md:mt-0 mt-8">
                    <p className="text-base font-medium leading-4 text-gray-800">
                      Anna Smith
                    </p>
                    <p className="text-base leading-4 mt-2 mb-4 text-gray-600">
                      Senior Web Designer
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </Slider>
        <div className="flex items-center mt-8">
          <ButtonBack
            className="cursor-pointer "
            role="button"
            aria-label="previous slide"
          >
            <img
              src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonal-svg2.svg"
              alt="previous"
            />
          </ButtonBack>

          <ButtonNext
            role="button"
            aria-label="next slide"
            className="cursor-pointer ml-2"
          >
            <img
              src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg3.svg"
              alt="next"
            />
          </ButtonNext>
        </div>
      </CarouselProvider> */}
    {/* </div>
    <br /> */}
    <br />
    <div>
      <h1 className="wh1">
        Here are some <span className="whc">rules we live </span>by
      </h1>
    </div>
    <div className="grid lg:grid-cols-2  md:grid-cols-2 gap-4 place-items-center ">
     
      
        <div>
          <img
            className="w-full h-[25rem]"
            src="/images/cat.png"
            alt="Sunset in the mountains"
          />
        </div>
        <div>
          <p className="wp1">1. Practice empathy on all directions</p>
          <p className="wp1">2. Be biased towards action over inaction</p>
          <p className="wp1">3. Practice transparency with context</p>
          <p className="wp1">4. Practice freedom with responsibility</p>
          <p className="wp1">5. Always assess Impact</p>
          <p className="wp1">6. Communication is a two way street</p>
          <p className="wp1">7. Stay curious</p>
          <p className="wp1">8. Show uncompromising Integrity</p>
          <p className="wp1">
            9. Promise yourself to achieve your full potential
          </p>
          <p className="wp1">
            Does this work for you? Find out if you are a good fit by taking a
            short quiz.
          </p>
          <button className="bg-transparent bg-red-500 blogcardbtn text-white-700 font-semibold hover:text-white  border border-blue-500 hover:border-transparent rounded">
            <span className="pcartbtntext">Take The Quiz</span>
          </button>
        </div>
      
    </div>
    <br />
    <Container>
      <div className="grid lg:grid-cols-2  md:grid-cols-2 gap-4 ">
        {/* <div>
          <h1 className="wp3">Send us your cover letter</h1>
          <p className="wp1">Hello</p>
          <p className="wp1">
            {" "}
            My name is <span className="whc"> Vinoth.</span> You can view my
            resume for the position of <span className="whc">Designer</span> @
            RAP
          </p>
          <p className="wp1">
            {" "}
            You can mail me @{" "}
            <span className="whc">vinoth.vijayamurugan@rap.ventures</span> or
            call me at<span className="whc"> 9876543210</span>
          </p>
          <p className="wp1">
            Glad to connect with you on Linkedin @{" "}
            <span className="whc">Vinoth_10</span> ( add your linkedin profile)
          </p>
        </div> */}
        <div className=" rounded  overflow-hidden shadow-lg  h-[27rem]">
          <form className="">
            <div className="md:flex md:items-center mb-6 pad8">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  From
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  className="h-[3.5rem] w-[30rem] bg-white border-b border-grey-500  p-8  rounded w-full py-2 px-4 text-gray-700 leading-tight "
                  id="inline-full-name"
                  type="text"
                  placeholder="JaneDoe@ventiure.com"
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6 pad8">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  
                </label>
              </div>
              <div className="md:w-2/3 ">
                <input
                  className="h-[3.5rem] w-[30rem] bg-white appearance-none border-b border-grey-500  p-8 inline-block rounded w-full py-2 px-4 text-gray-700 leading-tight "
                  id="inline-password"
                  type="text"
                  placeholder="Subject"
                />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6 pad8">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                
                </label>
              </div>
              <div className="md:w-2/3 ">
              <textarea
                    id="editor"
                    className="h-[5rem] w-[30rem] bg-white appearance-none border-b border-white  p-8 inline-block rounded w-full py-2 px-4 text-gray-700 leading-tight "
                  
                    placeholder="Write an article..."
                  ></textarea>
              </div>
            </div>
          
         
          
          
              <div className=" w-full bg-gray-50 rounded-lg border border-white dark:bg-gray-700 dark:border-white">
                <div className="flex justify-between items-center  px-3 border-b dark:border-white">
                  <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x dark:divide-gray-600">
                    <div className="flex items-center space-x-1 sm:pr-4">
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Attach file</span>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Embed map</span>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Upload image</span>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Format code</span>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Add emoji</span>
                      </button>
                    </div>
                    <div className="flex flex-wrap items-center space-x-1 sm:pl-4">
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Add list</span>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Settings</span>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Timeline</span>
                      </button>
                      <button
                        type="button"
                        className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Download</span>
                      </button>
                    </div>
                  </div>
                  <button
                    type="button"
                    data-tooltip-target="tooltip-fullscreen"
                    className="p-2 text-gray-500 rounded cursor-pointer sm:ml-auto hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Full screen</span>
                  </button>
                </div>
               
              </div>
              <button
                type="submit"
                className="ml-[1rem] px-5 py-2.5 text-sm font-medium text-center text-white bg-black rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
              >
                Send Now
              </button>
              
          
          </form>
         
        </div>
        <div>
          <img
            className="w-full h-[25rem]"
            src="/images/group.png"
            alt="Sunset in the mountains"
          />
        </div>
      </div>{" "}
      <br />
    </Container>
  </Layout>
)

export default SecondPage
